new_<template>
  <q-page class="flex flex-top">
    <div class="row full-width items-stretch">
      <div class="col bg-agora-light-4">
        <div class="row fit justify-end content-center items-center q-pr-xl">
          <q-img src="/statics/gan-small.png" style="height: 60px; max-width: 60px" />
          <h1 class="title q-pl-lg q-pr-xl">{{$t('global.site-name')}}</h1>
        </div>
      </div>

      <div class="col">
        <div class="row fit content-center q-pa-xl">
          <div class="col col-8">
            <div class="text-overline text-agora">{{$t('auth.reset_password')}}</div>
            <p>{{$t('auth.reset_password_instructions')}}</p>
            <form class="q-gutter-sm"
              @submit.prevent.stop="resetPassword"
              @reset.prevent.stop="reset"
              :disabled="pending">
              <base-input-text v-model="formData.password" ref="password"
                :label="$t('auth.new_password')" type="password" :rules="password_rules"
                color="agora" class="col col-5" />

              <base-input-text v-model="formData.password_confirmation" ref="password_confirmation"
                :label="$t('auth.password_confirmation')" type="password" :rules="password_rules"
                color="agora" class="col col-5" />

              <q-btn color="agora" class="col full-width" size="sm" unelevated type="submit">{{$t('form.send')}}</q-btn>
              <q-btn color="agora" class="col full-width" size="sm" flat to="/login">{{$t('auth.back_to_login_page')}}</q-btn>
            </form>
          </div>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
export default {
  name: 'ResetPasswordPage',

  data () {
    return {
      formData: {
        email: this.$route.query.email,
        token: this.$route.params.token,
        password: null,
        password_confirmation: null
      },
      pending: false,
      password_rules: [
        val => val.length > 6 || this.$t('auth.password_too_short')
      ]
    }
  },

  methods: {
    resetPassword () {
      ['password', 'password_confirmation'].forEach(field => this.$refs[field].validate())

      if (this.$refs['password'].hasError || this.$refs['password_confirmation'].hasError) {
        this.$q.notify({
          message: this.$t('auth.password_too_short'),
          color: 'negative'
        })
      } else if (this.formData.password !== this.formData.password_confirmation) {
        this.$q.notify({
          message: this.$t('auth.password_not_matching'),
          color: 'negative'
        })
      } else {
        this.pending = true

        this.$axios.post('/api/auth/password/reset', this.formData)
          .then(() => {
            this.$q.notify({
              message: this.$t('auth.password_reset_success'),
              color: 'positive'
            })
            this.$router.push({ name: 'login' })
          }).catch(error => this.$q.notify({
            message: this.$t(error),
            color: 'negative'
          })).finally(() => { this.pending = false })
      }
    },

    reset () {
      this.formData = {
        ...this.formData,
        email: null,
        password: null,
        password_confirmation: null
      }
    }
  }
}
</script>
